.pellet {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    color: white;
}

.startIcon > svg {
    vertical-align: middle;
}
