.legendGrid {
    margin-top: 8px;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(6, auto);
}

.legendGrid > div:nth-child(n) {
    display: flex;
    align-items: center;
    padding: 4px;
}

.legendGrid div:nth-child(n) > span {
    margin-left: 8px;
}

.legendGrid div:nth-child(n) > div {
    min-width: 46px;
    min-height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.legendGrid > div:nth-child(1) {
    order: 1;
}

.legendGrid > div:nth-child(2) {
    order: 2;
}

.legendGrid > div:nth-child(3) {
    order: 3;
}

.legendGrid > div:nth-child(4) {
    order: 4;
}

.legendGrid > div:nth-child(5) {
    order: 5;
}

.legendGrid > div:nth-child(6) {
    order: 6;
}

@media (min-width: 900px) {
    
    .legendGrid {
        grid-template-columns: repeat(2, minmax(auto, 400px));
        grid-template-rows: 1fr 1fr;
    }

    .legendGrid > div:nth-child(1) {
        order: 1;
    }
    
    .legendGrid > div:nth-child(2) {
        order: 3;
    }
    
    .legendGrid > div:nth-child(3) {
        order: 5;
    }
    
    .legendGrid > div:nth-child(4) {
        order: 2;
    }
    
    .legendGrid > div:nth-child(5) {
        order: 4;
    }
    
    .legendGrid > div:nth-child(6) {
        order: 6;
    }
}