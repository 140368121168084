.container {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(101deg, rgba(10, 10, 10, 0.03) 0%, rgba(10, 10, 10, 0.03) 22%, rgba(227, 227, 227, 0.03) 22%, rgba(227, 227, 227, 0.03) 65%, rgba(80, 80, 80, 0.03) 65%, rgba(80, 80, 80, 0.03) 100%), linear-gradient(204deg, rgba(185, 185, 185, 0.03) 0%, rgba(185, 185, 185, 0.03) 5%, rgba(9, 9, 9, 0.03) 5%, rgba(9, 9, 9, 0.03) 20%, rgba(247, 247, 247, 0.03) 20%, rgba(247, 247, 247, 0.03) 100%), linear-gradient(90deg, rgba(52, 145, 212, 0.21), rgba(52, 145, 212, 0.21));
}

/* --------------------------------------------------------------------- */
/* ------------------------------- MOBILE ------------------------------ */
/* --------------------------------------------------------------------- */

.mobileHeader {
    box-sizing: border-box;
    width: 100%;
    height: fit-content;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #03245a;
}

.mobileContent {
    padding: 48px 24px 48px 24px;
}

/* --------------------------------------------------------------------- */
/* ------------------------------- FOOTER ------------------------------ */
/* --------------------------------------------------------------------- */

.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px;
    text-align: center;
    color: rgb(60, 60, 60);
    font-size: 14px;
}

.footer > a {
    color: rgb(60, 60, 60);
    text-decoration: none;
    margin-left: 2px;
}

.footer > a:hover {
    text-decoration: underline;
}

/* --------------------------------------------------------------------- */
/* -------------------------------- PAPER ------------------------------ */
/* --------------------------------------------------------------------- */

.paper {
    flex: 1;
    display: flex;
    box-shadow: 0px 10px 30px -4px rgba(0, 0, 0, 0.4);
}

/* --------------------------------------------------------------------- */
/* ---------------------------- PAPER CONTENT -------------------------- */
/* --------------------------------------------------------------------- */

.paperContent {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    background-image: url(../../assets/images/unauthenticated-layout/backgroundXS.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.paperContentLogo {
    margin: 48px;
}

.paperContentTitle {
    opacity: 0;
    margin-left: 0px;
    margin-right: 48px;
    max-width: 510px;
    font-size: 22px;
    letter-spacing: 2px;
    color: white;
    animation: titleAppear 1.2s linear 0.4s forwards;
}

@keyframes titleAppear {
    to {
        opacity: 1;
        margin-left: 48px;
    }
}

.paperContentFillVoid {
    flex: 1;
}

.paperContentLink {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 48px;
    opacity: 0;
    animation: linkAppear 1.2s linear 1.6s forwards;
}

.paperContentLinkItem {
    padding: 8px 16px;
    border: 1px solid white;
    color: white;
    text-transform: uppercase;
    text-decoration: none;
}

.paperContentLinkItem:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

@keyframes linkAppear {
    to {
        opacity: 1;
    }
}

/* --------------------------------------------------------------------- */
/* ----------------------------- CHILDREN ------------------------------ */
/* --------------------------------------------------------------------- */

.children {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 48px;
    min-width: 400px;
    width: 400px;
    max-width: 400px;
}

/* --------------------------------------------------------------------- */
/* -------------------------------- MEDIA ------------------------------ */
/* --------------------------------------------------------------------- */

@media (min-width: 900px) {

    .container {
        flex-direction: row;
        padding: 32px 32px 48px 32px;
    }

    .locale {
        position: absolute !important;
        top: 8px !important;
        right: 8px !important;
    }
}

@media (min-width: 1200px) {

    .container {
        padding: 48px 64px;
    }

    .paperContent {
        background-image: url(../../assets/images/unauthenticated-layout/background.jpg);
    }

    .paperContentLogo {
        height: 40px;
        width: 296px;
    }

    .paperContentTitle {
        font-size: 28px;
    }
}

@media (min-width: 1536px) {
    
    .container {
        padding: 96px 128px;
    }

    .paperContentLogo {
        height: 50px;
        width: 370px;
    }

    .paperContentTitle {
        font-size: 32px;
    }
}
