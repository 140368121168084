.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 4px 8px;
    border-radius: 8px;
    color: white;
}

.roundedIcon {
    width: 20px;
    height: 20px;
    margin: 2px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
