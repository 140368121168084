.selected {
    box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    -webkit-box-sizing: border-box !important;
    border: 2px solid white !important;
    box-shadow: 0px 0px 2px 2px gray !important;
}

.deselected {
    color: rgba(0, 0, 0, 0.26) !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
    box-shadow: none !important;
}

.sorted {
    pointer-events: none;
}

.badgeContainer {
    position: absolute;
    z-index: 1;
    top: -14px;
    left: -22px; 
}

.iconContainer {
    position: absolute;
    z-index: 1;
    top: -14px;
    right: 16px;
}

.iconButton {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 0 2px gray;
    font-weight: 500;
    font-size: 16px;
    color: rgb(80, 80, 80);
}

.emptyBlock {
    width: 100%;
    height: 108px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: dashed;
    border-width: 2px;
    border-radius: 8px;
}
