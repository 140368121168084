.MobileTextField-drawer {
    position: fixed;
    z-index: 1300;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

    .MobileTextField-drawer > div {
        background-color: white;
        padding: 24px;
    }

    .MobileTextField-drawer .MuiInputAdornment-positionEnd {
        display: none;
    }