.breadcrumb {
    position: sticky;
    z-index: 1100;
    top: 0;
    padding: 8px 16px;
    box-shadow: 4px 0px 4px 2px rgba(0, 0, 0, 0.2);
    background-color: white;
}

.link {
    cursor: pointer;
    color: inherit;
    text-decoration: none;
}

    .link:hover {
        text-decoration: underline;
    }