:root {
    --app-bar-height: 64px;
    --app-bar-color: rgba(60, 60, 60);
    --bg-content-color: rgba(255, 255, 255, 0.7);
    --menu-expanded-width: 220px;
    --menu-reduced-width: 58px;
    --primary-color: #1976d2;
    --secondary-color: #9c27b0;
}

* {
	box-sizing: border-box;
}

html, body, #root {
    margin: 0;
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
}

body {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
    appearance: textfield;
    -moz-appearance: textfield;
}

#root {
    display: flex;
    flex-direction: column;
	overflow-x: hidden;
}

/* --------------------------------------------------------------------- */
/* ---------------------- REACT QUILL CSS OVERRIDE --------------------- */
/* --------------------------------------------------------------------- */

.ql-toolbar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: white;
}

.ql-container {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: white;
    min-height: 200px;
    font-size: 16px !important;
}

.ql-bubble {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

/* --------------------------------------------------------------------- */
/* -------------- REACT INTERNATIONAL PHONE CSS OVERRIDE --------------- */
/* --------------------------------------------------------------------- */

.react-international-phone-country-selector-button {
    height: 40px;
    padding-left: 4px;
    padding-right: 4px;
}

.react-international-phone-input {
    width: 100% !important;
    height: 40px !important;
    font-size: 16px !important;
}

.react-international-phone-error .react-international-phone-country-selector-button {
    border: 1px solid #d32f2f;
}

.react-international-phone-error .react-international-phone-input {
    border: 1px solid #d32f2f;
}

/* --------------------------------------------------------------------- */
/* --------------------------------------------------------------------- */
/* --------------------------------------------------------------------- */

.fs14 {
    font-size: 14px;
}

.fw500 {
    font-weight: 500;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.vaM {
    vertical-align: middle;
}

.subtitle {
    font-weight: 500;
    font-size: 14px;
    color: gray;
}

.break-word {
    overflow-wrap: break-word;
    word-break: break-word;
}

.back-to-line {
    white-space: pre-wrap;
}

.cursor-grab {
    cursor: grab;
}

.dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.dialog-title {
    margin-bottom: 16px;
	font-weight: 500;
	text-align: center;
	text-transform: uppercase;
}

.framed-block {
    border-width: 2px;
    border-style: dashed;
    border-radius: 8px;
    padding: 12px 24px;
}

.inline-flex {
    display: flex;
    align-items: center;
}

.table-td {
    padding: 12px;
    border: 1px solid lightgray;
    white-space: pre-wrap;
}

.unauthenticated-backdrop {
    position: absolute;
    z-index: 9999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
}

.container-padding {
    padding: 24px;
}
    
@media (min-width: 900px) and (max-width: 1536px) {
        
    .container-padding {
        padding: 32px;
    }
}

@media (min-width: 1536px) {
    
    .container-padding {
        padding: 48px;
    }
}
