.appBar {
    position: fixed;
    z-index: 1300;
    top: 0;
    left: 0;
    right: 0;
    height: var(--app-bar-height);
    max-height: var(--app-bar-height);
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--app-bar-color);
}

.leftContainer {
    display: flex;
    align-items: center;
}

.logo {
    vertical-align: middle;
}

.avatar {
    border: 2px solid white;
}