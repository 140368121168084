.SideMenu {
    position: fixed;
    z-index: 1200;
    top: 0;
    bottom: 0;
    left: 0;
    width: var(--menu-expanded-width);
    margin-top: var(--app-bar-height);
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    transition: width 0.6s ease-out;
}

.SideMenu-list {
    flex: 1;
    min-width: var(--menu-expanded-width);
    display: flex;
    flex-direction: column;
}

.NavLink {
    text-decoration: none;
    color: unset;
}

.NavLink-title {
    margin-left: 16px;
}

.NavLink-selected > div {
    background-color: rgb(240, 240, 240);
}
