.backgroundColor {
    background-color: var(--bg-content-color);
}

.image {
    width: 104px;
    height: 104px;
    border-radius: 50%;
    border: 2px solid white;
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.icon {
    width: 104px;
    height: 104px;
    border-radius: 50%;
    border: 2px solid white;
    background-color: rgba(189, 189, 189);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

    .icon > svg {
        color: white;
        width: 64px;
        height: 64px;
    }

.title {
    display: inline-flex;
    font-weight: 500;
    font-size: 20px;
}

.accordion {
    border-radius: 0px !important;
    box-shadow: none !important;
    background-color: transparent !important;
    margin-top: 0px !important;
}

    .accordion::before {
        content: none !important;
    }
