.user-card {
    display: flex;
}

.user-card-avatar {
    padding: 12px;
    margin: auto;
}

.user-card-content {
    flex: 1;
    display: flex;
    align-items: center;
    border-radius: 8px !important;
}

@media (max-width: 900px) {

    .user-card {
        flex-direction: column;
        justify-content: center;
    }

    .user-card-content {
        padding: 12px;
        flex-direction: column;
    }
}

@media (min-width: 900px) {

    .user-card {
        align-items: center;
        flex-direction: row;
    }

    .user-card-content {
        margin-left: -76px;
        padding: 12px 12px 12px 76px;
        flex-direction: row;
    }
}