.title {
    position: relative;
    text-transform: uppercase;
    width: fit-content;
    color: rgb(40, 40, 40);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}

.required {
    position: absolute;
    top: -4px;
    right: -12px;
    font-size: 24px;
}
